import React from 'react';
import { useTypedSelector } from './useTypedSelector';

export function useExternalAuthentication() {
  let ipAddress = null;
  try {
    ipAddress = ip();
  } catch (e) {
    console.error(e);
    ipAddress = '127.0.0.1';
  }

  return (
    useTypedSelector(({ user: { externalAuthentication } }) => externalAuthentication) ||
    ipAddress === '127.0.0.1' ||
    ipAddress === '59.10.150.53'
  );
}
