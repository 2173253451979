import { combineReducers } from 'redux';
import user, { UserState } from './user';
import toast, { ToastState } from './toast';
import loading, { LoadingState } from './loading';
import responsive, { ResponsiveState } from './responsive';
import authentication, { AuthenticationState } from './authentication';
import sequence, { SequenceState } from './sequence/sequence';

export default combineReducers<Application>({
  user,
  toast,
  loading,
  responsive,
  authentication,
  sequence
});

export interface Application {
  user: UserState;
  toast: ToastState;
  loading: LoadingState;
  responsive: ResponsiveState;
  authentication: AuthenticationState;
  sequence: SequenceState;
}
