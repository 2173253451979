import { all } from 'redux-saga/effects';
import user from './user';
import toast from './toast';
import loading from './loading';
import authentication from './authentication';
import sequence from './sequence';

export default function*() {
  yield all([user(), toast(), loading(), authentication(), sequence()]);
}
