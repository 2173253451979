import 'url-search-params-polyfill';
import './styles/index.module.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import createStore from './cores/createStore';
import { Provider } from 'react-redux';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';


axios.defaults.baseURL = process.env.REACT_APP_HOST;
axios.defaults.withCredentials = true;
const store = createStore();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
