import React, { FC, useEffect } from 'react';
import styles from './LoginPage.module.scss';
import base64url from 'base64-url';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { RouteComponentProps } from 'react-router-dom';
import Login from '../../components/Login/Login';
import { Application } from '../../store';
import { useTypedSelector } from '../../cores/useTypedSelector';

interface Props {}

const LoginPage: FC<Props & RouteComponentProps> = () => {
  return (
    <PageWrapper title="로그인">
      <Login />
    </PageWrapper>
  );
};

export default LoginPage;
