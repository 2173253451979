import produce from 'immer';
import { Reducer, handleActions, Action } from 'redux-actions';
import { action } from 'typesafe-actions';
import { getLocalStorageExternalAuthentication } from '../sagas/user';

export enum UserTypes {
  setExternalAuthentication = '@user/setExternalAuthentication'
}

export interface UserState {
  externalAuthentication: boolean;
}

export const UserActions = {
  setExternalAuthentication: (isExternalAuthentication: boolean) =>
    action(UserTypes.setExternalAuthentication, isExternalAuthentication),
};

const initialState: UserState = {
  externalAuthentication: getLocalStorageExternalAuthentication(),
};
export default handleActions<UserState, any>(
  {
    [UserTypes.setExternalAuthentication]: (state, action: Action<boolean>) => {
      return produce(state, draft => {
        draft.externalAuthentication = action.payload;
      });
    },
  },
  initialState
);
