import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import styles from './ExternalAuthentication.module.scss';
import { Vector } from '../../cores/Vector';
import { UserActions } from '../../store/user';
import { useDispatch } from 'react-redux';

interface Props {}

enum PositionTypes {
  Start,
  Enter,
  End
}

function getDirection(degree: number): number {
  if (degree >= 337.5 || degree <= 22.5) {
    return 1;
  } else if (degree > 22.5 && degree <= 67.5) {
    return 2;
  } else if (degree > 67.5 && degree <= 112.5) {
    return 3;
  } else if (degree > 112.5 && degree <= 157.5) {
    return 4;
  } else if (degree > 157.5 && degree <= 202.5) {
    return 5;
  } else if (degree > 202.5 && degree <= 247.5) {
    return 6;
  } else if (degree > 247.5 && degree <= 292.5) {
    return 7;
  } else {
    return 8;
  }
}

function getPosition(e: React.MouseEvent | React.TouchEvent): Vector {
  if (isTouch(e)) {
    return new Vector(e.touches[0].clientX, e.touches[0].clientY);
  } else {
    return new Vector(e.clientX, e.clientY);
  }
}

const standardDistance = Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)) / 45;

export function isTouch(e: React.TouchEvent | React.MouseEvent): e is React.TouchEvent {
  return e.nativeEvent instanceof TouchEvent;
}

const ExternalAuthentication: FC<Props> = memo(() => {
  const [isPressed, setPressed] = useState(false);
  const [positions, setPositions] = useState<Array<Vector>>([]);

  const dispatch = useDispatch();

  const pushPosition = useCallback(
    (e: React.MouseEvent | React.TouchEvent, type: PositionTypes) => {
      if (type === PositionTypes.Start) {
        const position = getPosition(e);
        setPositions(prevState => {
          prevState.push(position);
          return prevState;
        });
      } else if (type === PositionTypes.Enter) {
        const position = getPosition(e);
        if (positions.length > 0 && positions[positions.length - 1].distance(position) > standardDistance) {
          setPositions(prevState => {
            prevState.push(position);
            return prevState;
          });
        }
      } else if (type === PositionTypes.End) {
        const patterns: Array<number> = [];

        for (let i = 1, length = positions.length; i < length; i++) {
          const prev = positions[i - 1];
          const current = positions[i];

          const pattern = getDirection((Math.atan2(current.y - prev.y, current.x - prev.x) * 180) / Math.PI + 180);

          if (patterns[patterns.length - 1] !== pattern) {
            patterns.push(pattern);
          }
        }

        if (
          (patterns[0] === 5 && patterns[1] === 8 && patterns[patterns.length - 1] === 5) || // Z
          (patterns[0] === 1 && patterns[patterns.length - 1] === 3) // ㄴ
        ) {
          dispatch(UserActions.setExternalAuthentication(true));
        }

        setPositions([]);
      }
    },
    [positions]
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      setPressed(true);
      pushPosition(e, PositionTypes.Start);
    },
    [positions]
  );

  const onMouseUp = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      setPressed(false);
      pushPosition(e, PositionTypes.End);
    },
    [positions]
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (isPressed) {
        pushPosition(e, PositionTypes.Enter);
      }
    },
    [isPressed, positions]
  );

  return (
    <div
      className={styles.externalAuthentication}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchMove={onMouseMove}
      onTouchEnd={onMouseUp}
    >
      <div>
        <h1>403 Forbidden</h1>
      </div>
      <hr />
      <div>nginx/1.15.9</div>
    </div>
  );
});

export default ExternalAuthentication;
