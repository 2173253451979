import { all, take } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { UserActions, UserTypes } from '../store/user';
import { DateTime } from 'luxon';

export default function* rootSaga() {
  yield all([
    ...createAsyncSaga(UserActions),
    watchLocalStorageExternalAuthenticationSaga(),
  ]);
}

function* watchLocalStorageExternalAuthenticationSaga() {
  while (true) {
    const { payload } = yield take(UserTypes.setExternalAuthentication);
    setLocalStorageExternalAuthentication(payload);
  }
}

export function setLocalStorageExternalAuthentication(isExternalAuthentication: boolean) {
  localStorage.setItem('externalAuthenticationSaveTime', DateTime.local().toString());
  return localStorage.setItem('externalAuthentication', isExternalAuthentication ? 'true' : 'false');
}


export function getLocalStorageExternalAuthentication(): boolean {
  const externalAuthenticationSaveTime = localStorage.getItem('externalAuthenticationSaveTime');
  if (externalAuthenticationSaveTime) {
    const now = DateTime.local();
    const savedTime = DateTime.fromISO(externalAuthenticationSaveTime);
    const { milliseconds } = now.diff(savedTime).toObject();
    if (milliseconds && milliseconds > 24 * 3600 * 1000) {
      localStorage.setItem('externalAuthentication', 'false');
    }
  }

  return Boolean(localStorage.getItem('externalAuthentication'));
}
