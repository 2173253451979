import React, { FC, memo } from "react";
import styles from "./ToastMessage.module.scss";
import { Portal } from "react-portal";
import ToastMessageItem from "./ToastMessageItem/ToastMessageItem";
import { useTypedSelector } from "../../cores/useTypedSelector";

interface Props {}

const ToastMessage: FC<Props> = memo(() => {
  const toasts = useTypedSelector(({ toast }) => toast.toasts);

  return (
    <Portal>
      <div className={styles.toastMessage}>
        {toasts.map((toast, key) => (
          <ToastMessageItem
            key={toast.id}
            y={toasts.length - key - 1}
            {...toast}
          />
        ))}
      </div>
    </Portal>
  );
});

export default ToastMessage;
