import React, { FC, memo, useEffect } from "react";
import base64url from "base64-url";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import LoginPage from "../../pages/LoginPage/LoginPage";
import ToastMessage from "../ToastMessage/ToastMessage";
import { useTypedSelector } from "../../cores/useTypedSelector";
import { useExternalAuthentication } from "../../cores/useExternalAuthentication";
import ExternalAuthentication from "../ExternalAuthentication/ExternalAuthentication";
import { useRouter } from "../../cores/useRouter";
import { useToast } from "../../cores/useToast";

interface Props {}

// @ts-ignore
const App: FC<Props & RouteComponentProps> = memo(({ location, history }) => {
  const { token } = useTypedSelector(({ authentication }) => authentication);
  const externalAuthentication = useExternalAuthentication();
  const { query } = useRouter();
  const previous = query.get("previous");
  const toast = useToast();

  useEffect(() => {
    if (token) {
      if (previous) {
        window.location.replace(base64url.decode(previous));
      } else {
        toast.error("이전 사이트가 존재하지 않습니다.");
      }
    }
  });

  if (!externalAuthentication) {
    return <ExternalAuthentication />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
      </Switch>
      <ToastMessage />
    </React.Fragment>
  );
});

export default withRouter(App);
