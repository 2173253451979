export class Vector {
  x: number;
  y: number;

  constructor(x: number = 0, y: number = 0) {
    this.x = x;
    this.y = y;
  }

  translate(radian: number, distance: number = 1) {
    return new Vector(this.x + Math.cos(radian) * distance, this.y + Math.sin(radian) * distance);
  }

  distance(vector: Vector): number {
    return Math.sqrt(Math.pow(this.x - vector.x, 2) + Math.pow(this.y - vector.y, 2));
  }
}
